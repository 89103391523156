import React from "react"
import Layout from "../components/layout"
import Banner from "../components/Banner/banner2"
import ProjectsSummary from "../components/Projects/ProjectSummary"
import ProjectShortSummaryTable from "../components/Projects/ProjectShortSummaryTable"
import { graphql } from "gatsby"

export const query = graphql`
  query Projectos($locale: String!) {
    childProjetosJson: strapiProject(locale: { eq: $locale }) {
      Banner {
        bannerTitle
        background {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, maxHeight: 294) {
              ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      intro
      titlePost
      titlePhd
      titleMsc
      tableLabelsProposal
      tableLabelsStudent
      tableLabelsYear
      tableLabelsHost
      tableLabelsAdvisor
    }
  }
`;

export default ({ pageContext: { locale, currentPath }, data }) => {
  const content = data.childProjetosJson
  const tableLabels = {
    proposal: content.tableLabelsProposal,
    host: content.tableLabelsHost,
    advisor: content.tableLabelsAdvisor,
    student: content.tableLabelsStudent,
  };
  return (
    <Layout locale={locale} currentPath={currentPath}>
      <Banner
        banner={content.Banner.background.localFile}
        text={content.Banner.bannerTitle}
        layer={false}
        opacityValue={1}
        //logo={obcrei}
      />
      <div className="container">
        <div
          className="row justify-content-md-center"
          style={{ marginTop: 100, marginBottom: 40 }}>
          <div className=" col-sm-10 col-xs-12 text-default color-primary">
            {content.intro}
          </div>
        </div>

        <div className="row justify-content-md-center">
          <div className="col-sm-10 col-xs-12 text-left ">
            <h2 id="post-doc-title" style={{ fontSize: "2.3rem" }}>
              {content.titlePost}
            </h2>
          </div>
        </div>

        <ProjectsSummary locale={locale} degree={"postdoc"} />

        <div className="row justify-content-md-center">
          <div className="col-sm-10 col-xs-12 text-left ">
            <h2 id="phd-title" style={{ fontSize: "2.3rem" }}>
              {content.titlePhd}
            </h2>
          </div>
        </div>

        <ProjectsSummary locale={locale} degree={"phd"} />

        <div className="row justify-content-md-center">
          <div className="col-xs-12 text-left ">
            <h2 id="msc-title" style={{ fontSize: "2.3rem" }}>
              {content.titleMsc}
            </h2>
          </div>
        </div>
        <ProjectShortSummaryTable
          locale={locale}
          tableLabels={tableLabels}
        />
      </div>
    </Layout>
  )
}

// export const pessoas = graphql`
//   query($locale: String) {
//     file(
//       name: { eq: $locale }
//       relativeDirectory: { eq: "textContent/projetos" }
//     ) {
//       childProjetosJson {
//         bannerImage {
//           childImageSharp {
//             fluid(maxWidth: 1200, maxHeight: 294) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//         bannerText
//         intro
//         titlePost
//         titlePhd
//         titleMsc
//         tableLabels {
//           proposal
//           host
//           advisor
//           student
//         }
//       }
//     }
//   }
// `
