import React, { useState } from "react"
import _ from "lodash"
import { StaticQuery, graphql } from "gatsby"
import "./project-short-summary.scss"

export default ({ locale, tableLabels }) => (
  <StaticQuery
    query={graphql`
      query {
        allStrapiMcgic(sort: {fields: year, order: DESC}) {
          edges {
            node {
              proposta
              locale
              inst
              professor
              aluno
              link
              year
            }
          }
        }
      }
    `}
    render={data => {
      const content = data.allStrapiMcgic.edges.filter(
        d => d.node.locale === locale
      )

      const [seeMoreCount, setSeeMoreCount] = useState(1)

      const datasetToShow = _.slice(content, 0, seeMoreCount * 20)

      const remainingItems = content.length - datasetToShow.length

      return (
        <div className="msc-container">
          <div className="table-wrapper" style={{ marginBottom: 60 }}>
            {datasetToShow.map((item, i) => (
              <div className="row" key={i}>
                <div className="col col-sm-3 text-legend color-primary">
                  {tableLabels.proposal}
                  <br />
                  <span className="bold">
                    <a className="link--short-summary" href={item.node.link} target="_blank">
                      {item.node.proposta}
                    </a>
                  </span>
                  <div className="bold" style={{ marginTop: "3px" }}>
                    {item.node.year}
                  </div>
                </div>

                <div className="col col-sm-3 text-legend color-primary">
                  {tableLabels.host}
                  <br />
                  <span className="bold">{item.node.inst}</span>
                </div>
                <div className="col col-sm-3 text-legend color-primary">
                  {tableLabels.advisor}
                  <br />
                  <span className="bold">{item.node.professor}</span>
                </div>
                <div className="col col-sm-3 text-legend color-primary">
                  {tableLabels.student}
                  <br />
                  <span className="bold">{item.node.aluno}</span>
                </div>
                <div className="col col-xs-12 mt-3 mb-3 d-flex  align-items-center">
                  <div className="divider" style={{ height: 3 }} />
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div
              className="col-sm-12"
              style={{
                textAlign: "center",

                marginBottom: 120,
              }}>
              {remainingItems !== 0 && (
                <div
                  onClick={() => setSeeMoreCount(seeMoreCount + 1)}
                  className="btn btn-creialab text--legend"
                  style={{
                    color: "#ffffff",
                    minWidth: 146,
                    height: 38,
                    lineHeight: "25px",
                    cursor: "pointer",
                  }}>
                  {locale === "pt"
                    ? "Ver mais " + "(" + remainingItems + ")"
                    : "See more " + "(" + remainingItems + ")"}
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }}
  />
)
