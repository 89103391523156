import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import LocalizedLink from "../utils/LocalizedLink"
import _, { filter } from "lodash"

export default ({ locale, degree }) => (
  <StaticQuery
    query={graphql`
      query {
        allStrapiProjeto {
          edges {
            node {
              locale
              degree
              status
              title
              owner
              slug
              excerpt
              Reviewers {
                Name
                position
              }
            }
          }
        }
      }
    `}
    render={data => {
      const content = data.allStrapiProjeto.edges.filter(
        d => d.node.locale === locale && d.node.degree === degree
      )

      const [seeMoreCount, setSeeMoreCount] = useState(1)

      const datasetToShow = _.slice(content, 0, seeMoreCount * 2)

      const remainingItems = content.length - datasetToShow.length

      return (
        <div className="projects-container">
          {datasetToShow.map((d, i) => {
            return (
              <div className="project-wrapper" key={i}>
                <div className="row">
                  <div className="col-sm-1 d-flex  align-items-center">
                    <div className="divider" />
                  </div>
                  <div className=" col col-sm-10 col-xs-12 text-legend color-primary">
                    {d.node.status}
                  </div>
                </div>

                <div className="row justify-content-md-center">
                  <div className="col col-sm-10 col-xs-12">
                    <h3 className="mt--22 mb--22">{d.node.title}</h3>
                  </div>

                  <div className="col-sm-10 col-xs-12 text-default bold mb--22">
                    {d.node.owner}
                  </div>

                  <div className="col-sm-10 col-xs-12 text-default">
                    {d.node.Reviewers.map((n, i) => (
                      <p className="mb-1" key={i}>
                        <span className="bold">{n.position + ": "} </span>
                        {n.Name}
                      </p>
                    ))}
                  </div>

                  <div className="col-sm-10 col-xs-12 mt--22">
                    <LocalizedLink
                      to={`/projetos/${d.node.slug}`}
                      locale={locale}
                      className="link--red bold"
                      state={{ id: 1 }}>
                      {locale === "pt"
                        ? "Saber mais sobre o projeto >"
                        : "Know more about the project >"}
                    </LocalizedLink>
                  </div>
                </div>
              </div>
            )
          })}

          <div className="row">
            <div
              className="col-sm-12"
              style={{
                textAlign: "center",
                marginBottom: 120,
              }}>
              {remainingItems !== 0 && (
                <div
                  onClick={() => setSeeMoreCount(seeMoreCount + 1)}
                  className="btn btn-creialab text--legend"
                  style={{
                    color: "#ffffff",
                    minWidth: 146,
                    height: 38,
                    lineHeight: "25px",
                    cursor: "pointer",
                  }}>
                  {locale === "pt"
                    ? "Ver mais " + "(" + remainingItems + ")"
                    : "See more " + "(" + remainingItems + ")"}
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }}
  />
)
